@import "~flatpickr/dist/flatpickr.css";
@import "~@fancyapps/ui/dist/fancybox.css";
@import '~intl-tel-input/build/css/intlTelInput.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    touch-action: pan-y;
}

.breadcrumb .breadcrumb-item {
    position: relative;
}

.breadcrumb .breadcrumb-item:after {
    display: block;
    content: '/';
    position: absolute;
    right: -3px;
    top: 0;
}

.breadcrumb .breadcrumb-item:last-child:after {
    display: none;
}

.product-teaser:hover .product-teaser-placeholder {
    opacity: 1;
}

.menu-item-icon:hover svg {
    opacity: 0.8;
}

#city-select-form-button.opened svg,
#product-filters-form-sort-button.opened svg,
#product-filters-form-city-button.opened svg,
#product-filters-form-category-button.opened svg,
#product-filters-form-tags-button.opened svg {
    transform: rotate(180deg);
    margin-top: -3px;
}

.preformatted p {
    margin-bottom: 15px;
}
.preformatted > *:last-child {
    margin-bottom: 0;
}

.preformatted ul {
    padding-left: 20px;
}
.preformatted ul li {
    list-style-type: square;
    padding-left: 5px;
    margin-bottom: 3px;
}
.preformatted ul li:last-child {
    margin-bottom: 0;
}

.product-attribute-selector-link {
    @apply text-center p-2 block bg-white shadow rounded-md bg-white border border-2 border-transparent text-black;
}
.product-attribute-selector-link--active {
    @apply border-moon-spoon-brown;
}

.drop-in-animation {
    animation: dropin .2s cubic-bezier(.15, .8, .3, 1.6);
}

@keyframes dropin  {
    0% { transform: translateY(-6px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

.homepage-carousel .carousel__button {
    @apply hidden;
}

.homepage-carousel .carousel__dots {
    @apply flex;
}

@media screen(md) {
    .homepage-carousel .carousel__button {
        @apply flex;
    }
    .homepage-carousel .carousel__dots {
        @apply hidden;
    }
}

.cookies-slide-in {
    animation: cookieSlideIn 500ms cubic-bezier(0, 0, 0, 1);
}

@keyframes cookieSlideIn {
    0% { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

.cookies-slide-out {
    animation: cookieSlideOut 500ms cubic-bezier(0, 0, 0, 1);
}

@keyframes cookieSlideOut {
    0% { transform: translateY(0); }
    100% { transform: translateY(100%); }
}

.cart-drawer-animation-in {
    animation: productDrawerIn 300ms cubic-bezier(0, 0, 0, 1);
}

@keyframes productDrawerIn {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
}

.cart-drawer-animation-out {
    animation: productDrawerOut 300ms cubic-bezier(0, 0, 0, 1);
}

@keyframes productDrawerOut {
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
}

.iti {
    @apply w-full;
}
.backdrop-in {
    animation: backdropIn 300ms ease-out;
}

@keyframes backdropIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.backdrop-out {
    animation: backdropOut 300ms ease-in;
}

@keyframes backdropOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.popup-panel-in {
    animation: popupPanelIn 300ms ease-out;
}

@keyframes popupPanelIn {
    0% { @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95; }
    100% { @apply opacity-100 translate-y-0 sm:scale-100; }
}

.popup-panel-out {
    animation: popupPanelOut 300ms ease-out;
}

@keyframes popupPanelOut {
    0% { @apply opacity-100 translate-y-0 sm:scale-100; }
    100% { @apply opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95; }
}

.rating label:before {
    @apply content-['☆'] text-4xl text-moon-spoon-brown leading-normal;
}

.rating input:checked ~ label:before, .rating:not(:checked) > label:hover:before, .rating:not(:checked) > label:hover ~ label:before {
    @apply content-['★'] text-moon-spoon-brown;
}
